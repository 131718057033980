/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
// @import "assets/demo/styles/flags/flags.css";
// @import "assets/demo/styles/badges.scss";
// @import "assets/demo/styles/code.scss";

@import "~@fortawesome/fontawesome-free/css/all.min.css";

// Remove padding from top label menu
.layout-root-menuitem > .layout-menuitem-root-text { 
    padding: 0rem 2rem !important
}

/* Bootstrap progressbarr */
@-webkit-keyframes progress-bar-stripes {
    0% {
      background-position-x: 1rem
    }
}
  
@keyframes progress-bar-stripes {
    0% {
      background-position-x: 1rem
    }
}
  
.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0d6efd;
    transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    -webkit-animation: 1s linear infinite progress-bar-stripes;
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none
    }
}


progress {
    vertical-align: baseline;
}

// Remove shadows in sidebar and card
.layout-sidebar, .card {
    box-shadow: none !important;
}

.color-text-secondary {
    color: #8E8E8E !important;
}

.text-color-secondary {
    color: #686D74;
}

.grey-700 {
    color:#3A4D4B;
}

/** SPINNER **/
.evins-progress-spinner {
    position: fixed;
    z-index: 1200;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.evins-progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}
.evins-spinner .p-progress-spinner-circle {
    animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
    }
    
    @keyframes custom-progress-spinner-color {
        100%,
        0% {
            stroke: var(--primary-color)
        }
        
        90% {
            stroke: var(--primary-color)
        }
    }
    
    @keyframes custom-progress-spinner-dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

/* P-SCROLL: CUSTOM EVINS SCROLLBAR */
.evins-scroll .p-scrollpanel-bar {
    background-color: var(--primary-300);
    opacity: 1;
    transition: background-color .2s;
}
.evins-scroll .p-scrollpanel-bar:hover {
    background-color: var(--primary-500);
}
.evins-scroll .p-scrollpanel-bar:focus-visible {
    outline: 1px solid var(--p-focus-ring-color);
    outline-offset: 2px;
    box-shadow: none;
}

/* APPLY SURFACE-GROUND TO DROPDOWN AND INPUT-MASK */
.surface-ground input.p-inputtext,
.surface-ground .p-dropdown,
.surface-ground .p-dropdown-label {
    background-color: var(--surface-ground) !important;
}

/* ACCORDION MENU */
.menu-accordion .p-accordion-toggle-icon {
    display: none !important;
}

.menu-accordion .p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 1rem !important;
}

.menu-accordion .p-accordion-tab:not(.p-accordion-tab-active) {
    .p-toggleable-content {
    height: 0;
    overflow: hidden;
    }
}

.menu-accordion .p-accordion-tab.p-accordion-tab-active {
    .p-toggleable-content {
    height: auto !important;
    overflow: auto !important;
    }
}

/* WINE COLORS */
.evins-red-wine {
    color:#B10538 !important;
}

.evins-rose-wine {
    color:#FF8686 !important;
}

.evins-white-wine {
    color:#FFEC95 !important;
}

.evins-barrel-outline {
    stroke:#986842;
    stroke-width: 4
}

.evins-vessel-outline {
    stroke:#E0E0E0;
    stroke-width: 4
}

.digital-label-container {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 20px !important;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.digital-label-container > .container{
    max-width: 335px;
}

.qr-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.evins-label-table .p-datatable .p-datatable-table {
    border: 1px solid #e5e7eb;
    border-radius: 4px;
}

.evins-label-table .p-datatable .p-datatable-thead > tr > th {
    background-color: #f4f4f4;
    border-bottom: 1px solid #e5e7eb;
}

.evins-label-table .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.75rem;
}

.digital-label-container .footer{
    margin-top: 10px;
}

.evins-top-table{
    border-bottom: none !important;
}